import WelcomePage  from './home/compnents/welcome/body/WelcomePage';
import React from 'react';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
      <React.StrictMode> */}

        <WelcomePage/>
        {/* </React.StrictMode>
      </header> */}
    </div>
  );
}

export default App;
