import React from 'react';
import { Button, Typography } from 'antd';
import { useState, useEffect, useRef, useCallback } from 'react';
import './WelcomePage.css'; // Make sure you have this CSS file in the same directory
import ASRClient from './js/WebrtcConnections'
import { connectSocket, disconnectSocket, sendAudioData, onTextEvent, onAudioEvent, OnEnded, onStartEvent } from '../../../../socket';

const { Title } = Typography;

const WelcomePage = () => {
  const [startClicked, setStartClicked] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const [transscript, setTransscript] = useState("");
  const [aSRClient, setASRClient] = useState(null);
  const stopedSpeaking = useRef(null);
  const [webRtcStatus, setWebRtcStatus] = useState("0");
  const [btnText, setBtnText] = useState("Loading ..");

  //const[speech,setSpeech] = useState("")
  const speech = useRef("");
  let audio = useRef();

  const onFullSentance = useCallback((text) => {
    let spee = speech.current + text;
    console.log(spee);
    speech.current = spee;
    if (stopedSpeaking.current) {
      sendAudioData(speech.current)
      stopedSpeaking.current = false;
      handleStopRecording();
      setBtnText("Thinking ...");
    }
    //sendAudioData(text);
  }, []);

  const onConnected_ = useCallback(() => {
    setWebRtcStatus(0);
    setBtnText("Thinking...");

  }, []);
  useEffect(() => {
    // let asrClient_ = new ASRClient({
    //   host: "https://207.246.93.134:19000/api/rtc/asr",
    //   onConnected: () => onConnected_(),
    //   onLog: (log) => addLog(log),
    //   onError: (err) => {window.location.reload()},
    //   onInfer: (text, time) => onFullSentance(`${text} `),
    //   //onStateChanged: (recording) => setIsRecording(recording),
    // });


    //setASRClient(asrClient_);
    /// asrClient_.init().catch(console.error);

    if (typeof window.vadit === 'function') {
      window.vadit((vad_, wavBuffer) => {
        sendAudioData(wavBuffer);
        stopedSpeaking.current = true
        setBtnText("Thinking...");
        // asrClient_.stop();
        vad_.pause();
      }).then(vad => {
        setVad(vad);
        setBtnText("start");
      });
    }

    function addLog(message) {
      console.log(message)
      //setLog((prevLog) => [...prevLog, message]);
    }

  }, []);






  const [vad, setVad] = useState(null);

  //const onSpeechEndCB= useCallback();


  if (vad === null) {
    console.log("awaiting vad...")

  }



  const handleClick = () => {
    if (!startClicked) {
      //handleStartRecording();

      connectSocket();
      setBtnText("Starting ...");

      onTextEvent(function (text) {
        console.log(text)
        setTransscript(text);
      })

      onAudioEvent(function (audioBuffer) {
        const blob = new Blob([audioBuffer], { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(blob);
        handleStopRecording();
        setAudioUrl(audioUrl);
        setBtnText("stop");
      })
      OnEnded(() => {
        setStartClicked(false);
        disconnectSocket()
        alert("Test ended")

      })
      onStartEvent(function() {
        handleStartRecording();
      });
    }
    else {
      handleStopRecording();
      disconnectSocket();
      
      setBtnText("Start");
      audio.current.pause();
      audio.current.currentTime = 0;
    }

    if(audio) {
      try {
        audio.current.pause();
    }catch(ex){
        
    }
    }
    setStartClicked(!startClicked);
  };



  useEffect(() => {
    if (audioUrl) {
      handleStopRecording();
      if(audio) {
        try {
           audio.current.pause();
        }
        catch(ex) {

        }
      }

      audio.current = new Audio(audioUrl);
      audio.current.play();
      audio.current.onended = () => {
        handleStartRecording();
      };
    }
  }, [audioUrl]);

  const handleStartRecording = () => {
    speech.current = "";
    //aSRClient.start();
    setBtnText("Stop")
    vad.start();
  };

  const handleStopRecording = () => {
    if (aSRClient != null) {
      console.log("stoped")
      if(audio) {
        try {
           audio.current.pause();
        }
        catch(ex) {

        }
      }
      //   aSRClient.stop();
    }
    vad.pause();

  };
  return (
    <div className="welcome-container">
      <div className='flex'>
        <button style={{
         
          top: "20px",
          width: "62px",
          height: "35px",
          left: "30px",
          color: "white",
          position: "absolute",
          float: "left",
          background: "purple"
          }} onClick={()=> {window.location="https://speakroom.io"}} >Back</button>
      </div>
      <Title level={1}>Hello!</Title>
      <p>Let's have a conversation.</p>

      <div className="ring-container" onClick={handleClick}>
        <div className="glow-ring first"></div>
        {startClicked && <div className="glow-ring second active-session "></div>}
        {startClicked && <div className="glow-ring third"></div>}
        {startClicked && <div className="glow-ring fourth"></div>}
        {startClicked && <div className="glow-ring fifth"></div>}
        {startClicked && <div className="glow-ring sixth"></div>}
        {startClicked && <div className="glow-ring seventh"></div>}
        <Button type="ghost" shape="circle"
          className={`start-button ${startClicked ? 'active' : ''}`}
        >
          {btnText}
        </Button>
      </div>
      <div>
        <span style={{ color: "white" }}>{transscript}</span>

      </div>
      <div className="speed-settings">
        <span></span>
        
        {/* Add selectors for speed here if needed */}
      </div>
    </div>
  );
};

export default WelcomePage;
