// socketClient.js
import io from 'socket.io-client';
import pako from 'pako';

const SERVER_URL = 'wss://bot.hcsolutionz.com:3001'; // Replace with your server URL

let socket;

export const connectSocket = () => {
  socket = io(SERVER_URL);
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

export const sendAudioData = (audioBlob) => {
  if (socket) socket.emit('file_transfer',audioBlob);
};
export const onTextEvent = (callback) => {
  if (socket) {
    socket.on('text', callback);
  }
};
export const onAudioEvent = (callback) => {
  if (socket) {
    socket.on('audio', callback);
  }
};

export const onStartEvent = (callback) => {
  if (socket) {
    socket.on('start', callback);
  }
};
export const OnEnded = (OnEnded) => {
  if(socket) {
    socket.on('onEnded',OnEnded)
  }
}

export const getSocket = () => socket;

